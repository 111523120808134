import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  /* min-height: 600px; */
  position: relative;
  /* & > .container {
    flex-grow: 1;
  } */
`
