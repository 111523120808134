import countries from "./utils/countries";
import i18n from "./i18n";
import { settings } from './settings';

const LANG_KEY = 'lang'

export function getCurrentLanguage() {
  var currentLang = localStorage.getItem(LANG_KEY) || countries[settings.DEFAULT_LANGUAGE]
  return currentLang
}

export function setLanguage(lang) {
  localStorage.setItem(LANG_KEY, lang)
  i18n.changeLanguage(lang)
}

