const countries = {
  pl: 'pl',
  sk: 'sk',
  ua: 'ua',
  ru: 'ru',
  hu: 'hu',
  ro: 'ro',
  cz: 'cz',
  lt: 'lt',
  en: 'en',
  io: 'io',
  de: 'de',
  fr: 'fr',
  iq: 'iq'
}

export const countriesKeys = Object.keys(countries)

export default countries
