import moment from 'moment'
import { sessionEnd } from '../../../utils/stats';

export const session = {
  state: moment().valueOf(), // initial state
  reducers: {
    // handle state changes with pure functions
    start(state) {
      return state ? state : moment().valueOf()
    },
    stop() {
      return 0
    },
  },
  effects: {
    async finishSession(payload, rootState) {
      if (!rootState.session) {
        throw new Error('brak sesji')
      }
      const time = moment.duration(moment().diff(rootState.session))
      sessionEnd(time)
      this.stop()
    },

  },
}
