import store from '../../index'
import customHistory from '../../history'

export const idleMonitor = {
  state: {},
  reducers: {
    idle() {
      return 1
    },
    active() {
      return 0
    },
  },
  effects: {
    async onIdle(payload, rootState) {
      if (!rootState.session) {
        throw new Error('brak sesji')
      }
      customHistory.push('/')
      store.dispatch.session.finishSession()
    },
    onActive() {
      store.dispatch.session.start()
    },
  },
}
