import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { ConnectedRouter } from 'react-router-redux'
import { ThemeProvider } from 'styled-components'
import 'bootstrap/scss/bootstrap.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import './fonts/font-iconic/css/open-iconic-bootstrap.scss'
import 'whatwg-fetch'
import 'url-search-params-polyfill'

import './libpannellum'
import './pannellum'

import 'react-perfect-scrollbar/dist/css/styles.css'

import customHistory from './redux/history'
import store from './redux'
import main from './themes/main'
import './scss/styles.scss'
import i18n from './i18n'
import { callApi, apiCatch } from './utils/api'
import Loading from './components/Common/Loading'
import { IS_TOTEM } from './constants'
import App from './app'

/*var lastTime = 0
var fps = 30
var waitTime = 1000 / fps
window.requestAnimationFrame = function(callback) {
  var currTime = performance.now()
  var timeToCall = Math.max(0, waitTime - (currTime - lastTime))
  var id = setTimeout(function() {
    callback(currTime + timeToCall)
  }, timeToCall)
  lastTime = currTime + timeToCall
  return id
}*/

// Add fontawesome icons
library.add(faAngleLeft, faAngleRight)

function bootstrapApp() {
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={main}>
          <ConnectedRouter history={customHistory}>
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>,
    document.getElementById('app')
  )
}

if (IS_TOTEM) {
  ReactDOM.render(<Loading />, document.getElementById('app'))
  callApi
    .url(`/lang`)
    .get()
    .json(res => i18n.changeLanguage(res.lang))
    .then(() => {
      bootstrapApp()
    })
    .catch(apiCatch)
} else {
  bootstrapApp()
}
