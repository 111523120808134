import React from 'react'
import { BounceLoader } from 'react-spinners'
import styled from 'styled-components'

import { colors } from '../../utils/style'

const LoaderWrapper = styled.div`
  width: inherit;
  height: inherit;
  min-height: 150px;
  position: relative;
`

const Loader = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
`

const Loading = () => (
  <LoaderWrapper>
    <Loader>
      <BounceLoader type="oval" color={colors.blue} size={100} loading={true} />
    </Loader>
  </LoaderWrapper>
)

export default Loading
