import { css } from 'styled-components'

export const MOBILE_WIDTH = 767
export const TABLET_WIDTH = 1199

export const MOBILE_RADIUS = 12
export const TABLET_RADIUS = 15

export const media = {
  mobile: (...args) => css`
    @media (max-width: ${MOBILE_WIDTH}px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (max-width: ${TABLET_WIDTH}px) {
      ${css(...args)};
    }
  `,
}

export const colors = {
  lightGray: '#e6e9f0',
  blue: '#2a67ef',
}

export const common = {
  borderRadius: css`
    border-radius: ${TABLET_RADIUS}px;
    ${media.mobile`
      border-radius: ${MOBILE_RADIUS}px;
    `};
  `,
  border: css`
    border: solid 2px ${colors.lightGray};
  `,
}
