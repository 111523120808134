import i18n from 'i18next'

i18n.init({
  lng: 'pl',
  fallbackLng: 'pl',
  resources: {
    pl: {
      translations: {
        Header: 'Nagłówek',
        'VIRTUAL BATHROOM': 'WIRTUALNA ŁAZIENKA',
        'VIRTUAL BATHRROM desc':
          'Zwizualizuj swoją łazienkę i zobacz jak nasze płytki będą wyglądać u Ciebie w domu',
        CATALOG: 'KATALOG',
        CATALOGS: 'KATALOGI',
        'CATALOGS desc': 'Przejrzyj dostępne katalogi, wybierz to czego szukasz do swojej łazienki',
        COLLECTION: 'KOLEKCJA',
        VARIANT: 'WARIANT',
        BREADCRUMB1: 'Wybierz układ',
        BREADCRUMB2: 'Wybierz kolekcje',
        BREADCRUMB3: 'Wybierz wariant',
        BREADCRUMB4: 'Stwórz zestawienie',
        BREADCRUMB5: 'Karta kolekcji',
        BACKFOOTERCATALOGS: 'WRÓĆ DO EKRANU GŁÓWNEGO',
        BACKFOOTER1: 'WRÓĆ DO EKRANU GŁÓWNEGO',
        BACKFOOTER2: 'WRÓĆ DO WYBORU UKŁADU',
        BACKFOOTER3: 'WRÓĆ DO WYBORU KOLEKCJI',
        BACKFOOTER4: 'WRÓĆ DO WYBORU WARIANTU',
        BACKFOOTER5: 'WRÓĆ DO ZESTAWIENIA',
        BACK1: 'DO EKRANU GŁÓWNEGO',
        BACK2: 'DO WYBORU UKŁADU',
        BACK3: 'DO WYBORU KOLEKCJI',
        BACK4: 'DO WYBORU WARIANTU',
        BACK5: 'WRÓĆ DO ZESTAWIENIA',
        'BACK HOME': 'WRÓĆ DO EKRANU GŁÓWNEGO',
        'BACK TO CATALOGS': 'WRÓĆ DO KATALOGÓW',
        'PREVIOUS PAGE': 'POPRZEDNIA STRONA',
        'NEXT PAGE': 'NASTĘPNA STRONA',
        BATHROOMTYPEBATH: 'ŁAZIENKA Z WANNĄ',
        BATHROOMTYPESHOWER: 'ŁAZIENKA Z PRYSZNICEM',
        BATHROOMTYPEBOTH: 'ŁAZIENKA Z WANNĄ I PRYSZNICEM',
        BathroomTypeDesc:
          'Przejrzyj dostępne katalogi, wybierz to czego szukasz do swojej łazienki',
        ChangeVariant: 'Zmień wariant',
        Variant: 'Wariant',
        FrontWall: 'Ściana frontowa',
        SideWall: 'Ściana boczna',
        Floor: 'Podłoga',
        SendCombinationOnEmail: 'WYŚLIJ ZESTAWIENIE NA EMAIL',
        CollectionCard: 'Karta kolekcji',
        YOUR_COMBINATION: 'Twoje zestawienie',
        WRITE_YOUR_EMAIL: 'Wpisz swój email',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'W celu otrzymania elektronicznego formularza Twojego zestawienia prosimy o wpisanie aktualnego emaila.',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'Zgadzam się na przesłanie na wskazany adres e-mail elektronicznego formularza zestawienia przedstawiającego wybraną wizualizację łazienki. Wyrażenie zgody jest dobrowolne, ale niezbędne do realizacji usługi.',
        CONSENT_RECEIVING_EMAIL:
          'Zgadzam się na otrzymywanie drogą elektroniczną informacji o charakterze marketingowym, dotyczących marek firm Tubądzin Management Group Sp. z o.o., przesyłanych drogą elektroniczną, przy użyciu urządzeń telekomunikacyjnych i automatycznych systemów wywołujących (e-mail, serwisy internetowe, w tym portale społecznościowe) na podany przeze mnie adres e-mail.',
        PROCESS_DATA_DETAILS_LINK: 'Szczegóły...',
        PROCESS_DATA_DETAILS_HTML: `
          <ol>
            <li>
              Administratorem Pani/Pana danych osobowych jest Tubądzin Management Group Sp. z o.o. z
              siedzibą w Cedrowicach Parcela 11, 95-035 Ozorków.
            </li>
            <li>
              W sprawach dotyczących przetwarzania danych osobowych prosimy o kontakt z Inspektorem
              Ochrony Danych pod adresem e-mail: <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
            </li>
            <li>
              Pani/Pana dane osobowe przetwarzane są:
              <ul>
                <li>
                  w celu otrzymania elektronicznego formularza Twojego zestawienia przedstawiającego
                  wybraną wizualizację na wskazany adres poczty elektronicznej, na podstawie art. 6
                  ust. 1 lit. a) RODO
                </li>
                <li>
                  w celach prowadzenia działań marketingowych, na podstawie art. 6 ust. 1 lit. a)
                  RODO, po uzyskaniu dodatkowej zgody osoby, której dane dotyczą na wykorzystanie
                  środków komunikacji elektronicznej, zgodnie z przepisami ustawy o świadczeniu usług
                  drogą elektroniczną oraz ustawy Prawo telekomunikacyjne. Wyrażenie zgody jest zawsze
                  dobrowolne.
                </li>
              </ul>
            </li>
            <li>
              Dane osobowe będą przetwarzane do momentu ewentualnego odwołania przez Panią/Pana zgody,
              a następnie przez okres niezbędny do zabezpieczenia ewentualnych roszczeń związanych z
              przetwarzaniem danych.
            </li>
            <li>
              Pani/Pana dane osobowe będą udostępniane tylko podmiotom, którym Administrator ma
              obowiązek przekazywać dane na gruncie obowiązujących przepisów prawa, a także podmiotom,
              z którymi została zawarta stosowna umowa powierzenia przetwarzania danych osobowych.
            </li>
            <li>
              Posiada Pani/Pan prawo dostępu do treści swoich danych, ich sprostowania, usunięcia,
              ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu,
              prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem
              przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
            </li>
            <li>
              Posiada Pani/Pan prawo do wniesienia skargi, gdy uzna Pani/Pan, że przetwarzanie danych
              narusza przepisy r.o.d.o. Skargę należy wnieść do organu nadzorczego - Prezesa Urzędu
              Ochrony Danych Osobowych.
            </li>
          </ol>`,
        CLOSE: 'Zamknij',
        SEND: 'WYŚLIJ',
        SENDING: 'WYSYŁANIE...',
        COMBINATION_SENT_TO_EMAIL: 'Twoje zestawienie zostało wysłane na email',
        THANKS_USE_APP: 'Dziękujemy za skorzystanie z naszej aplikacji',
        REDIRECT_TO_HOME_PAGE: 'PRZENIEŚ MNIE DO EKRANU GŁÓWNEGO',
        OR: 'lub',
        CREATE_NEW_COMBINATION: 'STWÓRZ NOWE ZESTAWIENIE',
        SEND_EMAIL_ERROR:
          'Nie udało się wysłać zestawienia. Sprawdź poprawność adresu email. Jeżeli problem się powtórzy zgłoś administracji.',
        WRONG_EMAIL: 'Podano nieprawidłowy adres email',
        ERROR: 'Błąd',
        ERROR_API: 'Wystąpił błąd połączenia z serwerem. Spróbuj ponownie później.',
        ERROR_CODE: 'Kod jest błędny. Spróbuj ponownie.',
        PDFerror: 'Nie udało się załadować pliku PDF',
        PDFnoData: 'Brak Danych',
        NEW: 'Nowość',
        ZOOM: 'Powiększenie:',
      },
    },
    cz: {
      translations: {
        Header: 'Záhlaví',
        'VIRTUAL BATHROOM': 'VIRTUÁLNÍ KOUPELNA',
        'VIRTUAL BATHRROM desc':
          'Vizualizujte svou koupelnu a podívejte se, jak naše obklady vypadají u Vás doma.',
        CATALOG: 'KATALOG',
        CATALOGS: 'KATALOGY',
        'CATALOGS desc': 'Procházejte dostupné katalogy a vyberte to, co hledáte pro svou koupelnu',
        COLLECTION: 'PRODUKTOVÁ ŘADA',
        VARIANT: 'VERZE',
        BREADCRUMB1: 'Vyberte rozvržení',
        BREADCRUMB2: 'Vyberte produktové řady',
        BREADCRUMB3: 'Vyberte verzi',
        BREADCRUMB4: 'Vytvořte přehled',
        BREADCRUMB5: 'Karta produktové řady',
        BACKFOOTERCATALOGS: 'NÁVRAT NA HLAVNÍ OBRAZOVKU',
        BACKFOOTER1: 'NÁVRAT NA HLAVNÍ OBRAZOVKU',
        BACKFOOTER2: 'NÁVRAT NA VOLBU ROZVRŽENÍ',
        BACKFOOTER3: 'NÁVRAT NA VOLBU PRODUKTOVÉ ŘADY',
        BACKFOOTER4: 'NÁVRAT NA VOLBU VERZE',
        BACKFOOTER5: 'NÁVRAT NA PŘEHLED',
        BACK1: 'NA HLAVNÍ OBRAZOVKU',
        BACK2: 'NA VOLBU PŘEHLEDU',
        BACK3: 'NA VOLBU PRODUKTOVÉ ŘADY',
        BACK4: 'NA VOLBU VERZE',
        BACK5: 'NÁVRAT NA VOLBU ROZVRŽENÍ',
        'BACK HOME': 'NÁVRAT NA HLAVNÍ OBRAZOVKU',
        'BACK TO CATALOGS': 'NÁVRAT NA KATALOG',
        'PREVIOUS PAGE': 'PŘEDCHOZÍ STRÁNKA',
        'NEXT PAGE': 'DALŠÍ STRÁNKA',
        BATHROOMTYPEBATH: 'KOUPELNA S VANOU',
        BATHROOMTYPESHOWER: 'KOUPELNA SE SPRCHOU',
        BATHROOMTYPEBOTH: 'KOUPELNA S VANOU A SPRCHOU',
        BathroomTypeDesc:
          'Procházejte dostupné katalogy a vyberte to, co hledáte pro svou koupelnu',
        ChangeVariant: 'Změnit variantu',
        Variant: 'Varianta',
        FrontWall: 'Čelní zeď',
        SideWall: 'Boční zeď',
        Floor: 'Podlaha',
        SendCombinationOnEmail: 'POSLAT PŘEHLED NA E-MAIL',
        CollectionCard: 'Karta produktové řady',
        YOUR_COMBINATION: 'Tvůj přehled',
        WRITE_YOUR_EMAIL: 'ZADAT E-MAIL',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'Pokud chcete obdržet elektronický formulář Vašeho přehledu, zadejte svůj aktuální e-mail.',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'Souhlasím s odesláním elektronického formuláře představujícího vybranou vizualizaci koupelny na e-mailovou adresu. Vyjádření souhlasu je dobrovolné, ale je nevyhnutné pro poskytování služby.',
        CONSENT_RECEIVING_EMAIL:
          'Souhlasím se zasíláním marketingových informací týkajících se Tubądzin Management Group Sp. z o.o., zasílaných elektronicky, pomocí telekomunikačních zařízení a automatických volacích systémů (e-mail, webové stránky, včetně sociálních sítí) na mnou uvedenou e-mailovou adresu.',
        PROCESS_DATA_DETAILS_LINK: 'Podrobnosti...',
        PROCESS_DATA_DETAILS_HTML: `
          <ol>
            <li>
              Správcem Vašich osobních údajů je společnost Tubądzin Management Group Sp. z o.o. se sídlem v Cedrowice Parcela 11, 95-035 Ozorków.
            </li>
            <li>
              V záležitostech týkajících se zpracování osobních údajů kontaktujte úředníka pro ochranu údajů na této e-mailové adrese: <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
            </li>
            <li>
              Vaše osobní údaje jsou zpracovávané:
              <ul>
                <li>
                  za účelem obdržení elektronické formy Vašeho prohlášení s prezentací vybrané vizualizace na uvedenou e-mailovou adresu podle čl. 6 doložka 1 lit. a) GDPR
                </li>
                <li>
                  na účely marketingových aktivit, v souladu s čl. 6 odst. 1 písm. a) GDPR, po získání dalšího souhlasu dotčené osoby k používání elektronických komunikačních prostředků, v souladu s ustanoveními zákona o poskytování elektronických služeb a zákona o telekomunikacích. Vyjádření souhlasu je vždy dobrovolné.
                </li>
              </ul>
            </li>
            <li>
              Osobní údaje budou zpracovávané, dokud nezrušíte svůj souhlas, a poté po dobu nevyhnutnou na zabezpečení jakýchkoliv nároků souvisejících se zpracováním údajů.
            </li>
            <li>
              Vaše osobní údaje budou spřístupněné pouze subjektům, kterým je Správce povinen tyto poskytnout podle platných právních předpisů, jako i subjektům, s kterými byla uzavřená příslušná smlouva o pověření zpracováním osobních údajů.
            </li>
            <li>
              Máte právo na přístup ke svým údajům, jejich opravu, vymazání, omezení zpracování, právo na přenos údajů, právo namítat, právo kdykoliv odvolat souhlas bez toho, aby to mělo vlyv na zákonnost zpracování, které se vykonalo na základe souhlasu před jeho poskytnutím, odstoupení.
            </li>
            <li>
              Pokud máte pocit, že zpracování údajů porušuje ustanovení GDPR, máte právo podat stížnost. Stížnost by se měla podat dozorčímu orgánu - předsedovi Úřadu pro ochranu osobních údajů.
            </li>
          </ol>`,
        CLOSE: 'Zavřít',
        SEND: 'POSLAT',
        SENDING: 'ODESÍLÁNÍ...',
        COMBINATION_SENT_TO_EMAIL: 'Váš přehled byl odeslán na e-mail',
        THANKS_USE_APP: 'Děkujeme Vám za používání naší aplikace',
        REDIRECT_TO_HOME_PAGE: 'PŘESUNOUT NA HLAVNÍ OBRAZOVKU',
        OR: 'nebo',
        CREATE_NEW_COMBINATION: 'VYTVOŘIT NOVÝ PŘEHLED',
        SEND_EMAIL_ERROR:
          'Nepodařilo se odeslat přehled. Zkontrolujte, zda je e-mailová adresa správná. Pokud problém přetrvává, oznámte to administrační podpoře.',
        WRONG_EMAIL: 'Zadaná neplatná e-mailová adresa',
        ERROR: 'Chyba',
        ERROR_API: 'Došlo k chybě připojení k serveru. Zkuste to prosím znovu později.',
        ERROR_CODE: 'Kód je nesprávný. Zkuste to znovu.',
        PDFerror: 'Načtení PDF se nezdařilo',
        PDFnoData: 'Žádná data',
        NEW: 'Novinka',
        ZOOM: 'Zvýšit:',
      },
    },
    sk: {
      translations: {
        Header: 'Nadpis',
        'VIRTUAL BATHROOM': 'VIRTUÁLNA KÚPEĽŇA',
        'VIRTUAL BATHRROM desc':
          'Vizualizujte svoju kúpeľňu a pozrite sa, ako naše obklady vyzerajú u vás doma.',
        CATALOG: 'KATALÓG',
        CATALOGS: 'KATALÓGY',
        'CATALOGS desc':
          'Prechádzajte dostupné katalógy a vyberte to, čo hľadáte pre svoju kúpeľňu',
        COLLECTION: 'PRODUKTOVÁ RADA',
        VARIANT: 'VERZIA',
        BREADCRUMB1: 'Vyberte rozloženie',
        BREADCRUMB2: 'Vyberte produktové rady',
        BREADCRUMB3: 'Vyberte verziu',
        BREADCRUMB4: 'Vytvorte prehľad',
        BREADCRUMB5: 'Karta produktovej rady',
        BACKFOOTERCATALOGS: 'NÁVRAT NA HLAVNÚ OBRAZOVKU',
        BACKFOOTER1: 'NÁVRAT NA HLAVNÚ OBRAZOVKU',
        BACKFOOTER2: 'NÁVRAT NA VOĽBU ROZVRHNUTIA',
        BACKFOOTER3: 'NÁVRAT NA VOĽBU PRODUKTOVEJ RADY',
        BACKFOOTER4: 'NÁVRAT NA VOĽBU VERZIE',
        BACKFOOTER5: 'NÁVRAT NA PREHĽAD',
        BACK1: 'NA HLAVNÚ OBRAZOVKU',
        BACK2: 'NA VOĽBU PREHĽADU',
        BACK3: 'NA VOĽBU PRODUKTOVEJ RADY',
        BACK4: 'NA VOĽBU VERZIE',
        BACK5: 'NÁVRAT NA VOĽBU ROZVRHNUTIA',
        'BACK HOME': 'NÁVRAT NA HLAVNÚ OBRAZOVKU',
        'BACK TO CATALOGS': 'NÁVRAT NA KATALÓG',
        'PREVIOUS PAGE': 'PREDCHÁDZAJÚCA STRÁNKA',
        'NEXT PAGE': 'ĎALŠIA STRÁNKA',
        BATHROOMTYPEBATH: 'KÚPEĽŇA S VAŇOU',
        BATHROOMTYPESHOWER: 'KÚPEĽŇA SO SPRCHOU',
        BATHROOMTYPEBOTH: 'KÚPEĽŇA S VAŇOU A SPRCHOU',
        BathroomTypeDesc:
          'Prechádzajte dostupné katalógy a vyberte to, čo hľadáte pre svoju kúpeľňu',
        ChangeVariant: 'Zmeniť variantu',
        Variant: 'Varianta',
        FrontWall: 'Čelná stena',
        SideWall: 'Bočná stena',
        Floor: 'Podlaha',
        SendCombinationOnEmail: 'POSLAŤ PREHĽAD NA E-MAIL',
        CollectionCard: 'Karta produktovej rady',
        YOUR_COMBINATION: 'Tvoj prehľad',
        WRITE_YOUR_EMAIL: 'ZADAŤ E-MAIL',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'Ak chcete dostať elektronický formulár Vášho prehľadu, zadajte svoj aktuálny e-mail.',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'Súhlasím s odoslaním na e-mailovú adresu elektronického formulára predstavujúceho vybranú vizualizáciu kúpeľne. Vyjadrenie súhlasu je dobrovoľné, ale je nevyhnutné pre poskytovanie služby.',
        CONSENT_RECEIVING_EMAIL:
          'Súhlasím so zasielaním elektronicky marketingových informácií týkajúcich sa Tubądzin Management Group Sp. z o.o., zasielaných elektronicky, pomocou telekomunikačných zariadení a automatických volacích systémov (e-mail, webové stránky, vrátane sociálnych sietí) na mnou uvedenú e-mailovú adresu.',
        PROCESS_DATA_DETAILS_LINK: 'Podrobnosti...',
        PROCESS_DATA_DETAILS_HTML: `
          <ol>
            <li>
              Správcom vašich osobných údajov je spoločnosť Tubądzin Management Group Sp. z o.o. so sídlom v Cedrowice Parcela 11, 95-035 Ozorków.
            </li>
            <li>
              V záležitostiach týkajúcich sa spracovania osobných údajov kontaktujte úradníka pre ochranu údajov na tejto e-mailovej adrese: <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
            </li>
            <li>
              Vaše osobné údaje sú spracovávané:
              <ul>
                <li>
                  za účelom prijatia elektronickej formy vášho prehlásenia s prezentáciou vybranej vizualizácie na uvedenú e-mailovú adresu podľa čl. 6 doložka 1 lit. a) GDPR
                </li>
                <li>
                  na účely marketingových aktivít, v súlade s čl. 6 ods. 1 pism. a) GDPR, po získaní ďalšieho súhlasu dotknutej osoby k používaniu elektronických komunikačných prostriedkov, v súlade s ustanoveniami zákona o poskytovaní elektronických služieb a zákona o telekomunikáciách. Vyjadrenie súhlasu je vždy dobrovoľné.
                </li>
              </ul>
            </li>
            <li>
              Osobné údaje budú spracovávané, kým nezrušíte svoj súhlas, a potom po dobu nevyhnutnú na zabezpečenie akýchkoľvek nárokov súvisiacich so spracovaním údajov.
            </li>
            <li>
              Vaše osobné údaje budú sprístupnené iba subjektom, ktorým je Správca povinný poskytnúť údaje podľa platných právnych predpisov, ako aj subjektom, s ktorými bola uzavretá príslušná zmluva o poverení spracovaním osobných údajov.
            </li>
            <li>
              Máte právo na prístup k svojim údajom, ich opravu, vymazanie, obmedzenie spracovania, právo na prenos údajov, právo namietať, právo kedykoľvek odvolať súhlas bez toho, aby to malo vplyv na zákonnosť spracovania, ktoré sa vykonalo na základe súhlasu pred jeho poskytnutím. odstúpenie.
            </li>
            <li>
              Pokiaľ máte pocit, že spracovanie údajov porušuje ustanovenia GDPR, máte právo podať sťažnosť. Sťažnosť by sa mala podať dozornému orgánu - predsedovi Úradu pre ochranu osobných údajov.
            </li>
          </ol>`,
        CLOSE: 'Zavrieť',
        SEND: 'POSLAŤ',
        SENDING: 'ODOSIELANIE...',
        COMBINATION_SENT_TO_EMAIL: 'Váš prehľad bol odoslaný na e-mail',
        THANKS_USE_APP: 'Ďakujeme vám za používanie našej aplikácie',
        REDIRECT_TO_HOME_PAGE: 'PRESUNÚŤ  NA HLAVNÚ OBRAZOVKU',
        OR: 'alebo',
        CREATE_NEW_COMBINATION: 'VYTVORIŤ NOVY PREHĽAD',
        SEND_EMAIL_ERROR:
          'Nepodarilo sa odoslať prehľad. Skontrolujte, či je e-mailová adresa správna. Ak problém pretrváva, oznámte to administračnej podpore.',
        WRONG_EMAIL: 'Zadaná neplatná e-mailová adresa',
        ERROR: 'Chyba',
        ERROR_API: 'Došlo k chybe pripojenia k serveru. Skúste to znova neskôr.',
        ERROR_CODE: 'Kód je nesprávny. Skúste to znova.',
        PDFerror: 'Načítanie PDF zlyhalo',
        PDFnoData: 'Žiadne dáta',
        NEW: 'Novinka',
        ZOOM: 'Zväčšenie:',
      },
    },
    ru: {
      translations: {
        Header: 'Заголовок',
        'VIRTUAL BATHROOM': 'ВИРТУАЛЬНАЯ ВАННАЯ КОМНАТА',
        'VIRTUAL BATHRROM desc':
          'Визуализируйте вашу ванную комнату и посмотрите, как будет выглядеть наша плитка в вашем доме',
        CATALOG: 'КАТАЛОГ',
        CATALOGS: 'КАТАЛОГИ',
        'CATALOGS desc':
          'Просмотрите доступные каталоги, выберите то, что вы ищете для своей ванной комнаты',
        COLLECTION: 'КОЛЛЕКЦИЯ',
        VARIANT: 'ВАРИАНТ',
        BREADCRUMB1: 'Выберите макет',
        BREADCRUMB2: 'Выберите коллекцию',
        BREADCRUMB3: 'Выберите вариант',
        BREADCRUMB4: 'Создать комбинацию',
        BREADCRUMB5: 'Карта коллекции',
        BACKFOOTERCATALOGS: 'ВЕРНУТЬСЯ НА ГЛАВНЫЙ ЭКРАН',
        BACKFOOTER1: 'ВЕРНУТЬСЯ НА ГЛАВНЫЙ ЭКРАН',
        BACKFOOTER2: 'ВЕРНУТЬСЯ К ВЫБОРУ МАКЕТА',
        BACKFOOTER3: 'ВЕРНУТЬСЯ К ВЫБОРУ КОЛЛЕКЦИИ',
        BACKFOOTER4: 'ВЕРНУТЬСЯ К ВЫБОРУ ВАРИАНТА',
        BACKFOOTER5: 'ВЕРНУТЬСЯ К КОМБИНАЦИИ',
        BACK1: 'НА ГЛАВНЫЙ ЭКРАН',
        BACK2: 'К ВЫБОРУ МАКЕТА',
        BACK3: 'К ВЫБОРУ КОЛЛЕКЦИИ',
        BACK4: 'К ВЫБОРУ ВАРИАНТА',
        BACK5: 'ВЕРНУТЬСЯ К КОМБИНАЦИИ',
        'BACK HOME': 'ВЕРНУТЬСЯ НА ГЛАВНЫЙ ЭКРАН',
        'BACK TO CATALOGS': 'ВЕРНУТЬСЯ К КАТАЛОГАМ',
        'PREVIOUS PAGE': 'ПРЕДЫДУЩАЯ СТРАНИЦА',
        'NEXT PAGE': 'СЛЕДУЮЩАЯ СТРАНИЦА',
        BATHROOMTYPEBATH: 'ВАННАЯ КОМНАТА С ВАННОЙ',
        BATHROOMTYPESHOWER: 'ВАННАЯ КОМНАТА С ДУШЕМ',
        BATHROOMTYPEBOTH: 'ВАННАЯ КОМНАТА С ВАННОЙ И ДУШЕМ',
        BathroomTypeDesc:
          'Просмотрите доступные каталоги, выберите то, что вы ищете для своей ванной комнаты',
        ChangeVariant: 'Измените вариант',
        Variant: 'Вариант',
        FrontWall: 'Фронтальная стена',
        SideWall: 'Боковая стена',
        Floor: 'Пол',
        SendCombinationOnEmail: 'ОТПРАВТЕ КОМБИНАЦИЮ ПО ЭЛЕКТРОННОЙ ПОЧТЕ',
        CollectionCard: 'Карта коллекции',
        YOUR_COMBINATION: 'Ваша комбинация',
        WRITE_YOUR_EMAIL: 'Введите адрес вашей электронной почты',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'Для того, чтобы получить вашу комбинацию в электронной форме, пожалуйста, введите свой текущий адрес электронной почты.',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'Соглашаюсь с отправкой на указанный адрес электронной почты электронной формы с выбранной визуализацией ванной комнаты. Согласие является добровольным, но необходимым для осуществления данной услуги.',
        CONSENT_RECEIVING_EMAIL:
          'Соглашаюсь получать по электронной почте маркетинговую информацию о марках Tubądzin Management Group Sp. z o.o., отправленную в электронном виде, с использованием телекоммуникационных устройств и систем автоматической связи (e-mail, веб-страницы, включая социальные сети) на указанный мною адрес электронной почты.',
        PROCESS_DATA_DETAILS_LINK: 'Детали…',
        PROCESS_DATA_DETAILS_HTML: `
        <ol>
          <li>
            Администратором Ваших персональных данных является Tubądzin Management Group Sp. z o.o. с местонахождением в Цедровицах-Парцеля 11, 95-035 Озоркув.
          </li>
          <li>
          В случае возникновения вопросов, связанных с обработкой персональных данных, просим обращаться к Инспектору по защите данных по указанному адресу электронной почты: <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
          </li>
          <li>
            Ваши персональные данные обрабатываются:
            <ul>
              <li>
              для получения электронной формы вашей комбинации с выбранной визуализацией на указанный адрес электронной почты в соответствии со статьей 6, п.1, букв.a) RODO.
              </li>
              <li>
              для целей маркетинговой деятельности, в соответствии со статьей 6, п.1, букв.а) RODO, после получения дополнительного согласия субъекта данных на использование средств электронной связи, в соответствии с положениями Закона о предоставлении электронных услуг и Закона о телекоммуникациях. Согласие всегда дается на добровольной основе.
              </li>
            </ul>
          </li>
          <li>
          Персональные данные будут обрабатываться до момента, пока вы не отзовёте свое согласие, а затем в течение периода, необходимого для рассмотрения возможных претензий, связанных с обработкой  данных.
          </li>
          <li>
          Ваши персональные данные будут доступны только тем лицам, которым Администратор обязан передать их в соответствии с действующим законодательством, а также лицам, с которыми заключен соответствующий договор о передаче прав на обработку персональных данных.
          </li>
          <li>
          Вы имеете право на доступ к содержанию своих данных, исправление, удаление, ограничение обработки, право на передачу данных, право на возражение, право на отзыв согласия в любое время без нарушения закона об персональных данных, обработка которых осуществлялась на основании согласия до момента его отзыва.
          </li>
          <li>
          Вы имеете право подать жалобу, если считаете, что обработка данных нарушает условия положений r.o.d.o. Жалоба должна быть подана в надзорный орган - Председателю Управления по защите персональных данных.
          </li>
        </ol>`,
        CLOSE: 'Закрыть',
        SEND: 'ОТПРАВИТЬ',
        SENDING: 'ОТПРАВКА…',
        COMBINATION_SENT_TO_EMAIL: 'Ваша комбинация была отправлено по электронной почте',
        THANKS_USE_APP: 'Спасибо за использование нашего приложения',
        REDIRECT_TO_HOME_PAGE: 'ПЕРЕЙТИ НА ГЛАВНЫЙ ЭКРАН',
        OR: 'либо',
        CREATE_NEW_COMBINATION: 'СОЗДАЙТЕ НОВУЮ КОМБИНАЦИЮ',
        SEND_EMAIL_ERROR:
          'Мы не смогли отправить комбинацию. Проверьте правильность адреса электронной почты. Если проблема не решена, сообщите об этом администрации.',
        WRONG_EMAIL: 'Введен неверный адрес электронной почты',
        ERROR: 'Ошибка',
        ERROR_API: 'Произошла ошибка подключения к серверу. Попробуй еще раз позже.',
        ERROR_CODE: 'Код неверен. Попробуйте еще раз.',
        PDFerror: 'Не удалось загрузить PDF-файл',
        PDFnoData: 'Нет данных',
        NEW: 'Новинка',
        ZOOM: 'увеличить:',
      },
    },
    fr: {
      translations: {
        Header: '',
        'VIRTUAL BATHROOM': 'SALLE DE BAINS VIRTUELLE',
        'VIRTUAL BATHRROM desc':
          'Visualisez votre salle de bain et voyez à quoi ressembleront nos carreaux dans votre maison',
        CATALOG: 'CATALOGUE',
        CATALOGS: 'CATALOGUES',
        'CATALOGS desc':
          'Parcourez les catalogues disponibles, choisissez ce que vous recherchez pour votre salle de bain',
        COLLECTION: 'COLLECTION',
        VARIANT: 'VARIANTE',
        BREADCRUMB1: 'Choix disposition',
        BREADCRUMB2: 'Choix collection',
        BREADCRUMB3: 'Sélectionner une variante',
        BREADCRUMB4: 'Résumé',
        BREADCRUMB5: 'Fiche  collection',
        BACKFOOTERCATALOGS: "RETOUR À L'ÉCRAN D'ACCUEIL",
        BACKFOOTER1: "RETOUR À L'ÉCRAN D'ACCUEIL",
        BACKFOOTER2: 'RETOUR À LA SÉLECTION DE LA DISPOSITION',
        BACKFOOTER3: 'RETOUR À LA SÉLECTION DES COLLECTIONS',
        BACKFOOTER4: 'RETOUR À LA SÉLECTION DE LA VARIANTE',
        BACKFOOTER5: 'RETOUR AU RÉSUMÉ',
        BACK1: "ÉCRAN D'ACCUEIL",
        BACK2: 'SÉLECTION DE LA DISPOSITION',
        BACK3: 'SÉLECTION DES COLLECTIONS',
        BACK4: 'SÉLECTION DE LA VARIANTE',
        BACK5: 'RETOUR AU RÉSUMÉ',
        'BACK HOME': "RETOUR À L'ÉCRAN D'ACCUEIL",
        'BACK TO CATALOGS': 'RETOUR AUX CATALOGUES',
        'PREVIOUS PAGE': 'PAGE PRÉCÉDENTE',
        'NEXT PAGE': 'PAGE SUIVANTE',
        BATHROOMTYPEBATH: 'SALLE DE BAIN AVEC BAIGNOIRE',
        BATHROOMTYPESHOWER: 'SALLE DE BAIN AVEC DOUCHE',
        BATHROOMTYPEBOTH: 'SALLE DE BAIN AVEC BAIGNOIRE ET DOUCHE',
        BathroomTypeDesc:
          'Parcourez les catalogues disponibles, choisissez ce que vous recherchez pour votre salle de bain',
        ChangeVariant: 'Changer la variante',
        Variant: 'Variante',
        FrontWall: 'Paroi avant',
        SideWall: 'Paroi latérale',
        Floor: 'Sol',
        SendCombinationOnEmail: '',
        CollectionCard: 'Fiche de la collection',
        YOUR_COMBINATION: 'Votre résumé',
        WRITE_YOUR_EMAIL: '',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION: '',
        CONSENT_PROCESSING_PERSONAL_DATA: '',
        CONSENT_RECEIVING_EMAIL: '',
        PROCESS_DATA_DETAILS_LINK: 'Détails…',
        PROCESS_DATA_DETAILS_HTML: ``,
        CLOSE: 'Fermer',
        SEND: '',
        SENDING: '',
        COMBINATION_SENT_TO_EMAIL: '',
        THANKS_USE_APP: '',
        REDIRECT_TO_HOME_PAGE: "PASSER À L'ÉCRAN D'ACCUEIL",
        OR: 'ou',
        CREATE_NEW_COMBINATION: 'CRÉER UN NOUVEAU RÉSUMÉ',
        SEND_EMAIL_ERROR: '',
        WRONG_EMAIL: '',
        ERROR: 'Erreur',
        ERROR_API: "Une erreur de connexion au serveur s'est produite. Réessayez plus tard.",
        ERROR_CODE: 'Le code est incorrect. Réessayez.',
        PDFerror: 'Impossible de télécharger le fichier PDF',
        PDFnoData: 'Aucunes données disponibles',
        NEW: 'Nouveauté',
        ZOOM: 'Augmenter:',
      },
    },
    hu: {
      translations: {
        Header: '',
        'VIRTUAL BATHROOM': 'VIRTUÁLIS FÜRDŐSZOBA',
        'VIRTUAL BATHRROM desc': 'Tevezd meg fürdőszobádat burkolólapjainkkal! ',
        CATALOG: 'KATALÓGUS',
        CATALOGS: 'KATALÓGUSOK',
        'CATALOGS desc': 'Katalógusok átlapozása, a szükséges elemek kiválasztása',
        COLLECTION: 'KOLLEKCIÓ',
        VARIANT: 'VARIÁCIÓK',
        BREADCRUMB1: 'Az elrendezés kiválasztása',
        BREADCRUMB2: 'Kollekció választás',
        BREADCRUMB3: 'Variáció választás',
        BREADCRUMB4: 'Összeállítás ',
        BREADCRUMB5: 'Kollekció bemutató',
        BACKFOOTERCATALOGS: 'VISSZA A FŐOLDALRA',
        BACKFOOTER1: 'VISSZA A FŐOLDALRA',
        BACKFOOTER2: 'VISSZA AZ ELRENDEZÉSHEZ',
        BACKFOOTER3: 'VISSZA A KOLLEKCIÓ VÁLASZTÁSHOZ',
        BACKFOOTER4: 'VISSZA A VARIÁCIÓ VÁLASZTÁSHOZ',
        BACKFOOTER5: 'VISSZA AZ ÖSSZEÁLLÍTÁSHOZ',
        BACK1: 'FŐOLDALRA',
        BACK2: 'AZ ELRENDEZÉS VÁLASZTÁSHOZ',
        BACK3: 'A KOLLEKCIÓ VÁLASZTÁSHOZ',
        BACK4: 'A VARIÁCIÓ VÁLASZTÁSHOZ',
        BACK5: 'VISSZA AZ ÖSSZEÁLLÍTÁSHOZ',
        'BACK HOME': 'VISSZA A FŐOLDALRA',
        'BACK TO CATALOGS': 'VISSZA A KATALÓGUSOKHOZ',
        'PREVIOUS PAGE': 'ELŐZŐ OLDAL',
        'NEXT PAGE': 'KÖVETKEZŐ OLDAL',
        BATHROOMTYPEBATH: 'FÜRDŐSZOBA KÁDDAL',
        BATHROOMTYPESHOWER: 'FÜRDŐSZOBA ZUHANYZÓVAL',
        BATHROOMTYPEBOTH: 'FÜRDŐSZOBA KÁDDAL ÉS ZUHANYZÓVAL',
        BathroomTypeDesc: 'A katalógus átlapozása, a  szükséges elemek kiválasztása',
        ChangeVariant: 'Másik variáció',
        Variant: 'Variáció',
        FrontWall: 'Elülső fal',
        SideWall: 'Oldalfal',
        Floor: 'Padló',
        SendCombinationOnEmail: '',
        CollectionCard: 'Kollekció  bemutató',
        YOUR_COMBINATION: 'Az összeállításod',
        WRITE_YOUR_EMAIL: '',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION: '',
        CONSENT_PROCESSING_PERSONAL_DATA: '',
        CONSENT_RECEIVING_EMAIL: '',
        PROCESS_DATA_DETAILS_LINK: 'Részletek...',
        PROCESS_DATA_DETAILS_HTML: ``,
        CLOSE: 'Bezár',
        SEND: '',
        SENDING: '',
        COMBINATION_SENT_TO_EMAIL: '',
        THANKS_USE_APP: '',
        REDIRECT_TO_HOME_PAGE: 'VISSZA A FŐOLDALRA',
        OR: 'vagy',
        CREATE_NEW_COMBINATION: 'ÚJ ÖSSZEÁLLÍTÁS LÉTREHOZÁSA',
        SEND_EMAIL_ERROR: '',
        WRONG_EMAIL: '',
        ERROR: 'Hiba',
        ERROR_API: 'Szerver csatlakozási hiba történt. Próbáld újra később.',
        ERROR_CODE: 'Hibás kód. Próbáld újra.',
        PDFerror: 'A PDF fájl betöltése sikertelen',
        PDFnoData: 'Nincs adat',
        NEW: 'Újdonság',
        ZOOM: 'Zoomolás:',
      },
    },
    lt: {
      translations: {
        Header: '',
        'VIRTUAL BATHROOM': 'VIRTUALUS VONIOS KAMBARYS',
        'VIRTUAL BATHRROM desc':
          'Vizualizuokite savo vonios kambarį ir pažiūrėkite, kaip mūsų plytelės atrodys Jūsų namie',
        CATALOG: 'KATALOGAS',
        CATALOGS: 'KATALOGAI',
        'CATALOGS desc':
          'Peržiūrėkite prieinamus katalogus, pasirinkite tai, ko ieškote savo vonios kambariui',
        COLLECTION: 'KOLEKCIJA',
        VARIANT: 'VARIANTAS',
        BREADCRUMB1: 'Pasirinkti išdėstymą',
        BREADCRUMB2: 'Pasirinkti kolekciją',
        BREADCRUMB3: 'Pasirinkti variantą',
        BREADCRUMB4: 'Sukurti apžvalgą',
        BREADCRUMB5: 'Kolekcijos asortimentas',
        BACKFOOTERCATALOGS: 'ATGAL Į PAGRINDINĮ PUSLAPĮ',
        BACKFOOTER1: 'ATGAL Į PAGRINDINĮ PUSLAPĮ',
        BACKFOOTER2: 'ATGAL Į IŠDĖSTYMO PASIRINKIMĄ',
        BACKFOOTER3: 'ATGAL Į KOLEKCIJOS PASIRINKIMĄ',
        BACKFOOTER4: 'ATGAL Į VARIANTO PASIRINKIMĄ',
        BACKFOOTER5: 'ATGAL Į APŽVALGĄ',
        BACK1: 'Į PAGRINDINĮ PUSLAPĮ',
        BACK2: 'Į IŠDĖSTYMO PASIRINKIMĄ',
        BACK3: 'Į KOLEKCIJOS PASIRINKIMĄ',
        BACK4: 'Į VARIANTO PASIRINKIMĄ',
        BACK5: 'ATGAL Į APŽVALGĄ',
        'BACK HOME': 'ATGAL Į PAGRINDINĮ PUSLAPĮ',
        'BACK TO CATALOGS': 'ATGAL Į KATALOGUS',
        'PREVIOUS PAGE': 'ANKSTESNIS PUSLAPIS',
        'NEXT PAGE': 'KITAS PUSLAPIS',
        BATHROOMTYPEBATH: 'VONIOS KAMBARYS SU VONIA',
        BATHROOMTYPESHOWER: 'VONIOS KAMBARYS SU DUŠU',
        BATHROOMTYPEBOTH: 'VONIOS KAMBARYS SU VONIA IR DUŠU',
        BathroomTypeDesc:
          'Peržiūrėkite prieinamus katalogus, pasirinkite tai, ko ieškote savo vonios kambariui',
        ChangeVariant: 'Keisti variantą',
        Variant: 'Variantas',
        FrontWall: 'Priekinė siena',
        SideWall: 'Šoninė siena',
        Floor: 'Grindys',
        SendCombinationOnEmail: '',
        CollectionCard: 'Kolekcijos asortimentas',
        YOUR_COMBINATION: 'Jūsų apžvalga',
        WRITE_YOUR_EMAIL: '',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION: '',
        CONSENT_PROCESSING_PERSONAL_DATA: '',
        CONSENT_RECEIVING_EMAIL: '',
        PROCESS_DATA_DETAILS_LINK: 'Detalės...',
        PROCESS_DATA_DETAILS_HTML: ``,
        CLOSE: 'Uždaryti',
        SEND: '',
        SENDING: '',
        COMBINATION_SENT_TO_EMAIL: '',
        THANKS_USE_APP: '',
        REDIRECT_TO_HOME_PAGE: 'PERKELK MANE Į PAGRINDINĮ PUSLAPĮ',
        OR: 'arba',
        CREATE_NEW_COMBINATION: 'SSUKURTI NAUJĄ APŽVALGĄ',
        SEND_EMAIL_ERROR: '',
        WRONG_EMAIL: '',
        ERROR: 'Klaida',
        ERROR_API: 'Prisijungimas prie serverio nepavyko. Pabandykite dar kartą vėliau.',
        ERROR_CODE: 'Kodas yra neteisingas. Pabandykite dar kartą.',
        PDFerror: 'Nepavyko įkelti PDF failo',
        PDFnoData: 'Nėra duomenų',
        NEW: 'Naujiena',
        ZOOM: 'Padidinti:',
      },
    },
    ro: {
      translations: {
        Header: '',
        'VIRTUAL BATHROOM': 'BAIE VIRTUALĂ',
        'VIRTUAL BATHRROM desc':
          'Vizualizați baia și vedeți cum vor arăta faianța și gresia noastră în casa dvs.',
        CATALOG: 'CATALOG',
        CATALOGS: 'CATALOAGE',
        'CATALOGS desc': 'Citiți cataloagele disponibile, alegeți ceea ce căutați pentru baia dvs.',
        COLLECTION: 'COLECȚIA',
        VARIANT: 'VARIANTA',
        BREADCRUMB1: 'Alegeți amplasamentul',
        BREADCRUMB2: 'Alegeți colecția',
        BREADCRUMB3: 'Alegeți varianta',
        BREADCRUMB4: 'Creați setul',
        BREADCRUMB5: 'Fișa colecției',
        BACKFOOTERCATALOGS: 'REVENIRE LA ECRANUL PRINCIPAL',
        BACKFOOTER1: 'REVENIRE LA ECRANUL PRINCIPAL',
        BACKFOOTER2: 'REVENIRE LA ALEGEREA AMPLASAMENTULUI',
        BACKFOOTER3: 'REVENIRE LA ALEGEREA COLECȚIEI',
        BACKFOOTER4: 'REVENIRE LA ALEGEREA VARIANTEI',
        BACKFOOTER5: 'REVENIRE LA CREAREA SETULUI',
        BACK1: 'LA ECRANUL PRINCIPAL',
        BACK2: 'LA ALEGEREA AMPLASAMENTULUI',
        BACK3: 'LA ALEGEREA COLECȚIEI',
        BACK4: 'LA ALEGEREA VARIANTEI',
        BACK5: 'REVENIRE LA SET',
        'BACK HOME': 'REVENIRE LA ECRANUL PRINCIPAL',
        'BACK TO CATALOGS': 'REVENIRE LA CATALOAGE',
        'PREVIOUS PAGE': 'PAGINA PRECEDENTĂ',
        'NEXT PAGE': 'PAGINA URMĂTOARE',
        BATHROOMTYPEBATH: 'BAIE CU CADĂ',
        BATHROOMTYPESHOWER: 'BAIE CU DUȘ',
        BATHROOMTYPEBOTH: 'BAIE CU CADĂ ȘI DUȘ',
        BathroomTypeDesc: 'Citiți cataloage disponibile, alegeți ceea ce căutați pentru baia dvs.',
        ChangeVariant: 'Schimbați varianta',
        Variant: 'Variantă',
        FrontWall: 'Perete frontal',
        SideWall: 'Perete lateral',
        Floor: 'Podea',
        SendCombinationOnEmail: '',
        CollectionCard: 'Fișa colecției',
        YOUR_COMBINATION: 'Lista dvs.',
        WRITE_YOUR_EMAIL: '',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION: '',
        CONSENT_PROCESSING_PERSONAL_DATA: '',
        CONSENT_RECEIVING_EMAIL: '',
        PROCESS_DATA_DETAILS_LINK: 'Detalii…',
        PROCESS_DATA_DETAILS_HTML: ``,
        CLOSE: 'Închideți',
        SEND: '',
        SENDING: '',
        COMBINATION_SENT_TO_EMAIL: '',
        THANKS_USE_APP: '',
        REDIRECT_TO_HOME_PAGE: 'DORESC SĂ REVIN LA ECRANUL PRINCIPAL',
        OR: 'sau',
        CREATE_NEW_COMBINATION: 'CREAZĂ O LISTĂ NOUĂ',
        SEND_EMAIL_ERROR: '',
        WRONG_EMAIL: '',
        ERROR: 'Eroare',
        ERROR_API: 'A apărut o eroare de conectare la server. Încercați din nou mai târziu.',
        ERROR_CODE: 'Codul este greșit. Încercați din nou.',
        PDFerror: 'NPDF-ul nu a putut fi încărcat.',
        PDFnoData: 'Lipsă date',
        NEW: 'Noutate',
        ZOOM: 'Zoom:',
      },
    },
    en: {
      translations: {
        Header: 'Heading',
        'VIRTUAL BATHROOM': 'VIRTUAL BATHROOM',
        'VIRTUAL BATHRROM desc':
          'Visualize your bathroom and see how our tiles will look in your home.',
        CATALOG: 'CATALOG',
        CATALOGS: 'CATALOGS',
        'CATALOGS desc':
          'Browse through the available catalogs, choose what you are looking for in your bathroom',
        COLLECTION: 'COLLECTION',
        VARIANT: 'OPTION',
        BREADCRUMB1: 'Select layout',
        BREADCRUMB2: 'Select collection',
        BREADCRUMB3: 'Select option',
        BREADCRUMB4: 'Create a list',
        BREADCRUMB5: 'Collection sheet',
        BACKFOOTERCATALOGS: 'RETURN TO THE MAIN SCREEN',
        BACKFOOTER1: 'RETURN TO THE MAIN SCREEN',
        BACKFOOTER2: 'RETURN TO THE LAYOUT SELECTION',
        BACKFOOTER3: 'RETURN TO THE COLLECTION SELECTION',
        BACKFOOTER4: 'RETURN TO THE OPTION SELECTION',
        BACKFOOTER5: 'RETURN TO THE LIST',
        BACK1: 'TO THE MAIN SCREEN',
        BACK2: 'TO THE LAYOUT SELECTION',
        BACK3: 'TO THE COLLECTION SELECTION',
        BACK4: 'TO THE OPTION SELECTION',
        BACK5: 'RETURN TO THE LIST',
        'BACK HOME': 'RETURN TO THE MAIN SCREEN',
        'BACK TO CATALOGS': 'RETURN TO THE CATALOGS',
        'PREVIOUS PAGE': 'PREVIOUS PAGE',
        'NEXT PAGE': 'NEXT PAGE',
        BATHROOMTYPEBATH: 'BATHROOM WITH A BATHTUB',
        BATHROOMTYPESHOWER: 'BATHROOM WITH A SHOWER',
        BATHROOMTYPEBOTH: 'BATHROOM WITH A BATHTUB AND A SHOWER',
        BathroomTypeDesc:
          'Browse through the available catalogs, choose what you are looking for in your bathroom',
        ChangeVariant: 'Change the option',
        Variant: 'Option',
        FrontWall: 'Front wall',
        SideWall: 'Side wall',
        Floor: 'Floor',
        SendCombinationOnEmail: 'SEND LIST TO EMAIL',
        CollectionCard: 'Collection sheet',
        YOUR_COMBINATION: 'Your list',
        WRITE_YOUR_EMAIL: 'Enter your email',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'In order to receive an electronic form containing your list, please enter your current email address.',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'I agree for an electronic form presenting the selected visualization of the bathroom to be sent to the indicated email address. Giving consent is voluntary, but necessary for the implementation of the service.',
        CONSENT_RECEIVING_EMAIL:
          'I agree to receive marketing information concerning the brands of Tubądzin Management Group Sp. z o.o., sent electronically, using telecommunications equipment and automatic calling systems (e-mail, websites, including social networking sites) to the e-mail address provided by me.',
        PROCESS_DATA_DETAILS_LINK: 'Details…',
        PROCESS_DATA_DETAILS_HTML: `
          <ol>
            <li>
              The controller of your personal data is Tubądzin Management Group Sp. z o.o. with its registered office in Cedrowice Parcela 11, 95-035 Ozorków.
            </li>
            <li>
              If you have any questions regarding the processing of your personal data, please contact the Data Protection Inspector at the e-mail address: <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
            </li>
            <li>
              Your personal data is being processed:
              <ul>
                <li>
                  in order to for you to receive an electronic form containing your list showing the visualisation of your choice at the indicated electronic mail address pursuant to Article 6 sec. 1 a) of the GDPR
                </li>
                <li>
                  for the purposes of marketing activities, pursuant to Article 6 sec. 1 a) of the GDPR, after obtaining an additional consent of the data subject for the use of electronic communication means, in accordance with the provisions of the Act on Provision of Electronic Services and the Telecommunications Act. Consent is always given on a voluntary basis.
                </li>
              </ul>
            </li>
            <li>
              Your personal data will be processed until your consent is revoked and then for the period necessary to secure any claims relating to the processing of your data.
            </li>
            <li>
              Your personal data will be made available only to entities to whom the Controller is obliged to transfer data under applicable laws, as well as to entities with whom a relevant agreement has been concluded to entrust the processing of personal data.
            </li>
            <li>
              You have the right to access the content of your data, to rectify it, to delete it, to restrict the processing, to transfer it, to object, to withdraw your consent at any time without affecting the lawfulness of the processing carried out on the basis of consent prior to its withdrawal.
            </li>
            <li>
              You have the right to lodge a complaint if you believe that the processing of the data violates the provisions of the GDPR. The complaint should be lodged with the supervisory authority - the President of the Personal Data Protection Office.
            </li>
          </ol>`,
        CLOSE: 'Close',
        SEND: 'SEND',
        SENDING: 'SENDING…',
        COMBINATION_SENT_TO_EMAIL: 'Your list was emailed',
        THANKS_USE_APP: 'Thank you for using our application',
        REDIRECT_TO_HOME_PAGE: 'TAKE ME TO THE MAIN SCREEN',
        OR: 'or',
        CREATE_NEW_COMBINATION: 'CREATE A NEW LIST',
        SEND_EMAIL_ERROR:
          'There was a problem sending the list. Check the email address for correctness. If the problem persists, please notify the administration.',
        WRONG_EMAIL: 'An invalid email address has been entered',
        ERROR: 'Error',
        ERROR_API: 'A server connection error occurred. Try again later.',
        ERROR_CODE: 'The code is incorrect. Try again.',
        PDFerror: 'The PDF file could not be uploaded',
        PDFnoData: 'No Data',
        NEW: 'New',
        ZOOM: 'Zoom:',
      },
    },
    ua: {
      translations: {
        Header: 'Заголовок',
        'VIRTUAL BATHROOM': 'ВІРТУАЛЬНА ВАННА КІМНАТА ',
        'VIRTUAL BATHRROM desc':
          'Візуалізуйте вашу ванну кімнату і подивіться, як буде виглядати наша плитка у вашому будинку ',
        CATALOG: 'КАТАЛОГ',
        CATALOGS: 'КАТАЛОГИ',
        'CATALOGS desc':
          'Перегляньте наявні каталоги і виберіть те, що ви шукаєте для своєї ванної кімнати ',
        COLLECTION: 'КОЛЕКЦІЯ',
        VARIANT: 'ВАРІАНТ',
        BREADCRUMB1: 'Оберіть макет ',
        BREADCRUMB2: 'Оберіть колекцію ',
        BREADCRUMB3: 'Оберіть варіант ',
        BREADCRUMB4: 'Створіть комбінацію ',
        BREADCRUMB5: 'Карта колекції',
        BACKFOOTERCATALOGS: 'ПОВЕРНУТИСЯ НА ГОЛОВНИЙ ЕКРАН',
        BACKFOOTER1: 'ПОВЕРНУТИСЯ НА ГОЛОВНИЙ ЕКРАН',
        BACKFOOTER2: 'ПОВЕРНУТИСЯ ДО ВИБОРУ МАКЕТА',
        BACKFOOTER3: 'ПОВЕРНУТИСЯ ДО ВИБОРУ КОЛЕКЦІЇ',
        BACKFOOTER4: 'ПОВЕРНУТИСЯ ДО ВИБОРУ ВАРІАНТА',
        BACKFOOTER5: 'ПОВЕРНУТИСЯ ДО КОМБІНАЦІЇ',
        BACK1: 'НА ГОЛОВНИЙ ЕКРАН',
        BACK2: 'ДО ВИБОРУ МАКЕТА',
        BACK3: 'ДО ВИБОРУ КОЛЕКЦІЇ',
        BACK4: 'ДО ВИБОРУ ВАРІАНТА',
        BACK5: 'ПОВЕРНУТИСЯ ДО КОМБІНАЦІЇ',
        'BACK HOME': 'ПОВЕРНУТИСЯ НА ГОЛОВНИЙ ЕКРАН',
        'BACK TO CATALOGS': 'ПОВЕРНУТИСЯ ДО КАТАЛОГІВ ',
        'PREVIOUS PAGE': 'ПОПЕРЕДНЯ СТОРІНКА',
        'NEXT PAGE': 'НАСТУПНА СТОРІНКА',
        BATHROOMTYPEBATH: 'ВАННА КІМНАТА З ВАННОЮ',
        BATHROOMTYPESHOWER: 'ВАННА КІМНАТА З ДУШЕМ',
        BATHROOMTYPEBOTH: 'ВАННА КІМНАТА З ВАННОЮ І ДУШЕМ',
        BathroomTypeDesc:
          'Перегляньте наявні каталоги і виберіть те, що ви шукаєте для своєї ванної кімнати',
        ChangeVariant: 'Змініть варіант ',
        Variant: 'Варіант',
        FrontWall: 'Фронтальна стіна',
        SideWall: 'Бічна стіна',
        Floor: 'Підлога',
        SendCombinationOnEmail: 'НАДІШЛІТЬ КОМБІНАЦІЮ ЕЛЕКТРОННОЮ ПОШТОЮ',
        CollectionCard: 'Карта колекції',
        YOUR_COMBINATION: 'Ваша комбінація',
        WRITE_YOUR_EMAIL: 'Введіть адресу вашої електронної пошти',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'Для того, щоб отримати вашу комбінацію в електронній формі, будь-ласка, введіть дійсну адресу електронної пошти. ',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'Погоджуюся з надсиланням на вказану адресу електронної пошти електронної форми з обраною візуалізацією ванної кімнати. Згода є добровільною та необхідною для надання даної послуги.',
        CONSENT_RECEIVING_EMAIL:
          "Погоджуюся отримувати електронною поштою маркетингову інформацію про марки Tubądzin Management Group Sp. z o.o., надіслану в електронному вигляді, з використанням телекомунікаційних пристроїв і систем автоматичного зв'язку (e-mail, веб-сторінки, включаючи соціальні мережі) на вказану мною адресу електронної пошти. ",
        PROCESS_DATA_DETAILS_LINK: 'Деталі...',
        PROCESS_DATA_DETAILS_HTML: `
          <ol>
            <li>
              Адміністратором Ваших персональних даних є Tubądzin Management Group Sp. z o.o. з місцерозташуванням в Цедровице-Парцеля 11, 95-035 Озоркув. 
            </li>
            <li>
              Якщо у вас виникнуть запитання, пов'язані з обробкою персональних даних, просимо звертатися до Інспектора з питань захисту даних за вказаною адресою електронної пошти:  <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
            </li>
            <li>
              Ваші персональні дані обробляються: 
              <ul>
                <li>
                  для отримання електронної форми вашої комбінації з обраною візуалізацією на вказану адресу електронної пошти, відповідно до статті 6, п. 1, літер.a) RODO.
                </li>
                <li>
                  для цілей маркетингової діяльності, відповідно до статті 6, п. 1, літер.а) RODO, після отримання додаткової згоди суб'єкта даних на використання засобів електронного зв'язку, відповідно до положень Закону про надання електронних послуг та Закону про телекомунікації. Згода завжди надається на добровільній основі. 
                </li>
              </ul>
            </li>
            <li>
              Персональні дані будуть оброблятися до моменту, поки ви не відкличете свою згоду, а потім протягом періоду, необхідного для розгляду можливих претензій, пов'язаних з обробкою даних. 
            </li>
            <li>
              Ваші персональні дані будуть доступні лише тим особам, яким Адміністратор зобов'язаний їх передати відповідно до чинного законодавства, а також особам, з якими укладено відповідний договір про передачу прав на обробку персональних даних.
            </li>
            <li>
              Ви маєте право на доступ до змісту своїх даних, виправлення, видалення, обмеження обробки, право на передачу даних, право на заперечення, право на відкликання згоди у будь-який час без порушення закону про персональні дані, обробка яких здійснювалася на підставі згоди до моменту її відкликання. 
            </li>
            <li>
              Ви маєте право подати скаргу, якщо вважаєте, що обробка даних порушує умови положень r.o.d.o. Скарга повинна бути подана до наглядового органу - Голові Управління із захисту персональних даних. 
            </li>
          </ol>`,
        CLOSE: 'Закрити',
        SEND: 'НАДІСЛАТИ',
        SENDING: 'НАДСИЛАННЯ…',
        COMBINATION_SENT_TO_EMAIL: 'Ваша комбінація була надіслана електронною поштою',
        THANKS_USE_APP: 'Дякуємо за використання нашого додатку',
        REDIRECT_TO_HOME_PAGE: 'ПЕРЕЙТИ НА ГОЛОВНИЙ ЕКРАН',
        OR: 'або',
        CREATE_NEW_COMBINATION: 'СТВОРИТИ НОВУ КОМБІНАЦІЮ',
        SEND_EMAIL_ERROR:
          'Ми не змогли надіслати комбінацію. Перевірте правильність адреси електронної пошти. Якщо проблема не вирішена, повідомте про це адміністрацію. ',
        WRONG_EMAIL: 'Введено неправильну адресу електронної пошти',
        ERROR: 'Помилка',
        ERROR_API: 'Сталася помилка підключення до сервера. Спробуйте ще раз пізніше.',
        ERROR_CODE: 'Код неправильний. Спробуйте ще раз.',
        PDFerror: 'Не вдалося завантажити PDF-файл',
        PDFnoData: 'Немає даних',
        NEW: 'Новинка',
        ZOOM: 'Збільшення:',
      },
    },
    de: {
      translations: {
        Header: 'Überschrift',
        'VIRTUAL BATHROOM': 'VIRTUELLES BADEZIMMER',
        'VIRTUAL BATHRROM desc':
          'Visualisieren Sie Ihr Badezimmer und prüfen Sie, welche Fliesen in Ihr Haus am besten passen.',
        CATALOG: 'KATALOG',
        CATALOGS: 'KATALOGE',
        'CATALOGS desc':
          'Schauen Sie die erhältlichen Kataloge durch und wählen Sie das aus, wonach Sie für Ihr Badezimmer suchen.',
        COLLECTION: 'KOLLEKTION',
        VARIANT: 'VARIANTE',
        BREADCRUMB1: 'Anordnung wählen',
        BREADCRUMB2: 'Kollektionen wählen',
        BREADCRUMB3: 'Variante wählen',
        BREADCRUMB4: 'Aufstellung erstellen',
        BREADCRUMB5: 'Kollektionsblatt',
        BACKFOOTERCATALOGS: 'ZURÜCK ZUM HAUPTBILDSCHIRM',
        BACKFOOTER1: 'ZURÜCK ZUM HAUPTBILDSCHIRM',
        BACKFOOTER2: 'ZURÜCK ZUR WAHL DER ANORDNUNG',
        BACKFOOTER3: 'ZURÜCK ZUR WAHL DER KOLLEKTION',
        BACKFOOTER4: 'ZURÜCK ZUR WAHL DER VARIANTE',
        BACKFOOTER5: 'ZURÜCK ZUR AUFSTELLUNG',
        BACK1: 'ZUM HAUPTBILDSCHIRM',
        BACK2: 'ZUR WAHL DER ANORDNUNG',
        BACK3: 'ZUR WAHL DER KOLLEKTION',
        BACK4: 'ZUR WAHL DER VARIANTE',
        BACK5: 'ZURÜCK ZUR AUFSTELLUNG',
        'BACK HOME': 'ZURÜCK ZUM HAUPTBILDSCHIRM',
        'BACK TO CATALOGS': 'ZURÜCK ZU KATALOGEN',
        'PREVIOUS PAGE': 'UNTERSEITE',
        'NEXT PAGE': 'NÄCHSTE SEITE',
        BATHROOMTYPEBATH: 'BADEZIMMER MIT BADEWANNE',
        BATHROOMTYPESHOWER: 'BADEZIMMER MIT DUSCHE',
        BATHROOMTYPEBOTH: 'BADEZIMMER MIT BADEWANNE UND DUSCHE',
        BathroomTypeDesc:
          'Schauen Sie die erhältlichen Kataloge durch und wählen Sie das aus, was Sie für Ihr Badezimmer suchen.',
        ChangeVariant: 'Variante ändern',
        Variant: 'Variante',
        FrontWall: 'Stirnseite',
        SideWall: 'Seitenwand',
        Floor: 'Fußboden ',
        SendCombinationOnEmail: 'AUFSTELLUNG PER E-MAIL SENDEN',
        CollectionCard: 'Kollektionsblatt',
        YOUR_COMBINATION: 'Ihre Aufstellung',
        WRITE_YOUR_EMAIL: 'Tragen Sie bitte Ihre E-Mail Adresse ein',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'Tragen Sie bitte Ihre aktuelle E-Mail Adresse ein, um Ihre Aufstellung als elektronisches Formular zu erhalten.',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'Ich bin damit einverstanden, dass die ausgewählte Visualisierung des Badezimmers als elektronisches Formular an die angegebene E-Mail Adresse gesendet wird. Die Erteilung der Einwilligung ist zwar freiwillig, aber für die Erbringung der Dienstleistung erforderlich.',
        CONSENT_RECEIVING_EMAIL:
          'Ich stimme der elektronischen Zustellung von Marketinginformationen über die Marken der Firma Tubądzin Management Group Sp. z o.o. zu, die elektronisch, mithilfe Kommunikations- und automatischer Anrufsysteme (E-Mail, Internetservices, darunter soziale Netzwerke) an die von mir angegebene E-Mail Adresse gesendet werden.',
        PROCESS_DATA_DETAILS_LINK: 'Einzelheiten...',
        PROCESS_DATA_DETAILS_HTML: `
          <ol>
            <li>
              Der Verwalter Ihrer personenbezogenen Daten ist Tubądzin Management Group Sp. z o.o. mit Sitz in Cedrowice Parcela 11, 95-035 Ozorków.
            </li>
            <li>
              In den Angelegenheiten bezogen auf die Verarbeitung personenbezogener Daten bitten wir um den Kontakt mit dem Datenschutzbeauftragten an die E-Mail Adresse: <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
            </li>
            <li>
              Ihre Daten werden zu den folgenden Zwecken verarbeitet:
              <ul>
                <li>
                  Zustellung Ihrer Aufstellung mit der ausgewählten Visualisierung als elektronisches Formular an die angegebene E-Mail, aufgrund des Art. 6 Abs. 1 Punkt a) DSVGO,
                </li>
                <li>
                  Für Marketingzwecke, aufgrund des Art. 6 Abs. 1 Punkt a) DSVGO, nach Einwirkung der zusätzlichen Einwilligung der betroffenen Person auf die Nutzung der elektronischen Kommunikationsmittel gemäß dem Gesetz über die elektronische Erbringung von Dienstleistungen sowie dem Telekommunikationsgesetz. Die Erteilung der Einwilligung ist freiwillig.
                </li>
              </ul>
            </li>
            <li>
              Die personenbezogenen Daten werden verarbeitet, bis Sie eventuell die Einwilligung widerrufen, und dann solange, wie es für die Sicherung der eventuellen Ansprüche wegen der Datenverarbeitung notwendig ist.
            </li>
            <li>
              Ihre personenbezogenen Daten werden nur den Subjekten übergeben, denen gegenüber der Verwalter aufgrund der geltenden Rechtsvorschriften zu übergeben verpflichtet ist, sowie den Subjekten, mit denen ein entsprechender Vertrag über die Übermittlung der Datenverarbeitung geschlossen wurde.
            </li>
            <li>
              Sie sind berechtigt, den Zugang zu Ihren Daten zu haben, sie zu berichtigen, zu löschen, die Verarbeitung einzuschränken, die Daten zu übertragen, den Widerspruch einzulegen, die Einwilligung jederzeit ohne Einfluss auf die Konformität mit dem Verarbeitungsrecht zu widerrufen, die aufgrund der Einwilligung vor ihrer Rücknahme erfolgte.
            </li>
            <li>
              Sie sind berechtigt, eine Beschwerde einzulegen, wenn Sie feststellen, dass die Datenverarbeitung die Bestimmungen der DSGVO verletzt. Die Beschwerde sollte man an das Aufsichtsorgan - Vorsitzender des Amtes für Personendatenschutz - einlegen.
            </li>
          </ol>`,
        CLOSE: 'Schließen',
        SEND: 'SENDEN',
        SENDING: 'ES WIRD GESENDET...',
        COMBINATION_SENT_TO_EMAIL: 'Ihre Aufstellung wurde an die E-Mail Adresse gesendet.',
        THANKS_USE_APP: 'Danke für die Benutzung unserer Applikation',
        REDIRECT_TO_HOME_PAGE: 'ZUM HAUPTBILDSCHIRM ÜBERGEHEN ',
        OR: 'oder',
        CREATE_NEW_COMBINATION: 'NEUE AUFSTELLUNG ERSTELLEN',
        SEND_EMAIL_ERROR:
          'Die Aufstellung wurde nicht gesendet. Prüfen Sie, ob die E-Mail Adresse richtig ist. Melden Sie an den Verwalter, wenn das Problem wiederholt.',
        WRONG_EMAIL: 'Die angegebene E-Mail Adresse ist falsch.',
        ERROR: 'Fehler',
        ERROR_API:
          'Ein Verbindungsfehler zum Server ist aufgetreten.  Versuchen Sie später nochmal.',
        ERROR_CODE: 'Der Kode ist falsch. Versuchen Sie nochmal.',
        PDFerror: 'Die PDF-Datei wurde nicht heruntergeladen.',
        PDFnoData: 'Keine Daten',
        NEW: 'Neuheit',
        ZOOM: 'Vergrößerung:',
      },
    },
    iq: {
      translations: {
        Header: '',
        'VIRTUAL BATHROOM': 'الحمام الافتراضي',
        'VIRTUAL BATHRROM desc':
          'قم بتصوير حمامك وشاهد كيف سيبدو سيراميكنا في منزلك',
        CATALOG: 'الكتالوج',
        CATALOGS: 'الكتالوجات',
        'CATALOGS desc':
          'تصفح الكتالوجات المتاحة، واختر ما تبحث عنه في الحمام الخاص بك',
        COLLECTION: 'المجموعة',
        VARIANT: 'الخيار',
        BREADCRUMB1: 'اختيار التصميم',
        BREADCRUMB2: 'اختيار المجموعات',
        BREADCRUMB3: 'اختيار البدائل',
        BREADCRUMB4: 'إنشاء ملخص',
        BREADCRUMB5: 'بطاقة المجموعات',
        BACKFOOTERCATALOGS: 'عودة إلى الشاشة الرئيسية',
        BACKFOOTER1: 'عودة إلى الشاشة الرئيسية',
        BACKFOOTER2: 'عودة إلى اختيار التصميم',
        BACKFOOTER3: 'عودة إلى اختيار المجموعة',
        BACKFOOTER4: 'عودة إلى اختيار البدائل',
        BACKFOOTER5: 'عودة إلى الملخص',
        BACK1: 'إلى الشاشة الرئيسية',
        BACK2: 'إلى اختيار التصميم',
        BACK3: 'إلى اختيار المجموعة',
        BACK4: 'إلى اختيار البدائل',
        BACK5: 'عودة إلى الملخص',
        'BACK HOME': 'عودة إلى الشاشة الرئيسية',
        'BACK TO CATALOGS': 'عودة إلى الكتالوج',
        'PREVIOUS PAGE': 'الصفحة السابقة',
        'NEXT PAGE': 'الصفحة التالية',
        BATHROOMTYPEBATH: 'حمام مع حوض استحمام',
        BATHROOMTYPESHOWER: 'حمام مع دوش',
        BATHROOMTYPEBOTH: 'حمام مع حوض استحمام ودوش',
        BathroomTypeDesc:
          'تصفح الفهارس المتاحة، اختر ما تبحث عنه في الحمام الخاص بك',
        ChangeVariant: 'تغيير البدائل',
        Variant: 'بديل',
        FrontWall: 'الحائط الأمامي',
        SideWall: 'الحائط الجانبي',
        Floor: 'الأرضية',
        SendCombinationOnEmail: '',
        CollectionCard: 'بطاقة المجموعة',
        YOUR_COMBINATION: 'حسابك',
        WRITE_YOUR_EMAIL: 'Enter your email',
        ENTER_EMAIL: 'email@example.com',
        SEND_COMBINATION_EMAIL_DESCRIPTION:
          'In order to receive an electronic form containing your list, please enter your current email address.',
        CONSENT_PROCESSING_PERSONAL_DATA:
          'I agree for an electronic form presenting the selected visualization of the bathroom to be sent to the indicated email address. Giving consent is voluntary, but necessary for the implementation of the service.',
        CONSENT_RECEIVING_EMAIL:
          'I agree to receive marketing information concerning the brands of Tubądzin Management Group Sp. z o.o., sent electronically, using telecommunications equipment and automatic calling systems (e-mail, websites, including social networking sites) to the e-mail address provided by me.',
        PROCESS_DATA_DETAILS_LINK: 'التفاصيل...',
        PROCESS_DATA_DETAILS_HTML: `
          <ol>
            <li>
              The controller of your personal data is Tubądzin Management Group Sp. z o.o. with its registered office in Cedrowice Parcela 11, 95-035 Ozorków.
            </li>
            <li>
              If you have any questions regarding the processing of your personal data, please contact the Data Protection Inspector at the e-mail address: <a href="mailto:iod@tubadzin.pl">iod@tubadzin.pl</a>
            </li>
            <li>
              Your personal data is being processed:
              <ul>
                <li>
                  in order to for you to receive an electronic form containing your list showing the visualisation of your choice at the indicated electronic mail address pursuant to Article 6 sec. 1 a) of the GDPR
                </li>
                <li>
                  for the purposes of marketing activities, pursuant to Article 6 sec. 1 a) of the GDPR, after obtaining an additional consent of the data subject for the use of electronic communication means, in accordance with the provisions of the Act on Provision of Electronic Services and the Telecommunications Act. Consent is always given on a voluntary basis.
                </li>
              </ul>
            </li>
            <li>
              Your personal data will be processed until your consent is revoked and then for the period necessary to secure any claims relating to the processing of your data.
            </li>
            <li>
              Your personal data will be made available only to entities to whom the Controller is obliged to transfer data under applicable laws, as well as to entities with whom a relevant agreement has been concluded to entrust the processing of personal data.
            </li>
            <li>
              You have the right to access the content of your data, to rectify it, to delete it, to restrict the processing, to transfer it, to object, to withdraw your consent at any time without affecting the lawfulness of the processing carried out on the basis of consent prior to its withdrawal.
            </li>
            <li>
              You have the right to lodge a complaint if you believe that the processing of the data violates the provisions of the GDPR. The complaint should be lodged with the supervisory authority - the President of the Personal Data Protection Office.
            </li>
          </ol>`,
        CLOSE: 'إغلاق',
        SEND: 'SEND',
        SENDING: 'SENDING…',
        COMBINATION_SENT_TO_EMAIL: 'Your list was emailed',
        THANKS_USE_APP: 'Thank you for using our application',
        REDIRECT_TO_HOME_PAGE: 'انتقل إلى الصفحة الرئيسية',
        OR: 'أو',
        CREATE_NEW_COMBINATION: 'إنشاء حساب جديد',
        SEND_EMAIL_ERROR:
          'There was a problem sending the list. Check the email address for correctness. If the problem persists, please notify the administration.',
        WRONG_EMAIL: 'An invalid email address has been entered',
        ERROR: 'خطأ',
        ERROR_API: 'حدث خطأ في اتصال الخادم. يرجى المحاولة مرة أخرى لاحقًا.',
        ERROR_CODE: 'الرمز غير صحيح. حاول مرة أخرى',
        PDFerror: 'فشل تحميل ملف PDF',
        PDFnoData: 'لا توجد بيانات',
        NEW: 'جديد',
        ZOOM: 'تكبير:',
      },
    }
  },
  debug: process.env.NODE_ENV !== 'production',

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
})

export const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || ''

export default i18n
