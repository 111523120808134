import { callApi } from './api'

const PREFIX = '/stats'

export const sessionEnd = time => {
  callApi.url(`${PREFIX}/session`).post({ time: time.subtract(3, 'minutes').valueOf() })
}
export const panoramaView = ({ collectionId, combinationId, type }) => {
  return callApi
    .url(`${PREFIX}/panorama`)
    .post({ collectionId, combinationId, type })
    .json()
}
export const collectionView = ( collectionId ) => {
  return callApi
    .url(`${PREFIX}/collection`)
    .post({ collectionId })
    .json()
}
export const visualisationView = ({ collectionId, combinationId, type }) => {
  return callApi
    .url(`${PREFIX}/visualisation`)
    .post({ collectionId, combinationId, type })
    .json()
}
export const catalogView = catalogId => {
  return callApi
    .url(`${PREFIX}/catalog`)
    .post({ catalogId })
    .json()
}
export const scannerUse = () => {
  return callApi
    .url(`${PREFIX}/scanner`)
    .post()
    .json()
}
