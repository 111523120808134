import React, { PureComponent } from 'react'
import { Switch, Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import swal from 'sweetalert2'

import Loading from '../components/Common/Loading'
import { Wrapper } from '../themes/global'
import ScreenSaver from './ScreenSaver'
import i18n from '../i18n'
import { IS_TOTEM, TO_ASCII, SHIFT_UPS } from '../constants'
import { callApi, apiCatch } from '../utils/api'
import customHistory from '../redux/history'
import { scannerUse } from '../utils/stats'

const Header = Loadable({
  loader: () => import('../components/Header/Header'),
  loading: Loading,
})

const FrontPage = Loadable({
  loader: () => import('./FrontPage'),
  loading: Loading,
})

const BathroomTypePage = Loadable({
  loader: () => import('./BathroomTypePage'),
  loading: Loading,
})

const CatalogsPage = Loadable({
  loader: () => import('./CatalogsPage'),
  loading: Loading,
})

const CollectionsPage = Loadable({
  loader: () => import('./CollectionsPage'),
  loading: Loading,
})

const VariantsPage = Loadable({
  loader: () => import('./VariantsPage'),
  loading: Loading,
})

const CombinationsPage = Loadable({
  loader: () => import('./CombinationsPage'),
  loading: Loading,
})

const CollectionCardPage = Loadable({
  loader: () => import('./CollectionCardPage'),
  loading: Loading,
})

class WrapperComponent extends PureComponent {
  code = ''

  keyListener = async e => {
    if (e.keyCode == 13) {
      e.stopPropagation()
      e.preventDefault()

      if (this.code) {
        const data = await callApi
          .url(`/collections/code/${this.code}`)
          .get()
          .json()
          .catch(apiCatch)
          .catch(() => {
            swal({ title: i18n.t('ERROR'), text: i18n.t('ERROR_CODE'), type: 'error' })
          })
        if (data) {
          scannerUse()
          const searchParams = new URLSearchParams(this.props.location.search)
          const type = searchParams.get('type')
          const { collectionId, variantId, combinationId } = data

          if (type === null) {
            customHistory.push(
              `/virtual/1?preload=true&collectionId=${collectionId}&variantId=${variantId}&combinationId=${combinationId}`
            )
          } else {
            customHistory.push(
              `/virtual/4?type=${type}&collectionId=${collectionId}&variantId=${variantId}&combinationId=${combinationId}`
            )
          }
        }
      } else {
        swal({ title: i18n.t('ERROR'), text: i18n.t('ERROR_CODE'), type: 'error' })
      }
      this.code = ''
      return
    }

    var c = e.which

    //normalize keyCode
    if (TO_ASCII.hasOwnProperty(c)) {
      c = TO_ASCII[c]
    }

    if (!e.shiftKey && (c >= 65 && c <= 90)) {
      c = String.fromCharCode(c + 32)
    } else if (e.shiftKey && SHIFT_UPS.hasOwnProperty(c)) {
      //get shifted keyCode value
      c = SHIFT_UPS[c]
    } else if (c >= 96 && c <= 105) {
      c = String.fromCharCode(c - 48)
    } else {
      c = String.fromCharCode(c)
    }

    this.code += c
  }

  componentDidMount() {
    if (IS_TOTEM) document.addEventListener('keydown', this.keyListener, false)

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }
  componentWillUnmount() {
    if (IS_TOTEM) document.removeEventListener('keydown', this.keyListener, false)
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.props.setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  getPath = path => {
    const { match } = this.props

    if (IS_TOTEM) {
      return path
    }

    return match.path + path
  }

  render() {
    return (
      <Wrapper>
        <ScreenSaver />
        <Switch>
          <Route path={this.getPath('/')} exact name="Front page" component={FrontPage} />
          <Route path={this.getPath('/catalog')} name="Catalog page" component={CatalogsPage} />
          <Route
            path={this.getPath('/virtual/1')}
            exact
            name="Bathroom type"
            component={BathroomTypePage}
          />
          <Route
            path={this.getPath('/virtual/2')}
            exact
            name="Collections"
            component={CollectionsPage}
          />
          <Route path={this.getPath('/virtual/3')} exact name="Variant" component={VariantsPage} />
          <Route
            path={this.getPath('/virtual/4')}
            name="Combination"
            component={CombinationsPage}
          />
          <Route
            path={this.getPath('/collection-card')}
            exact
            name="Combination"
            component={CollectionCardPage}
          />
        </Switch>
      </Wrapper>
    )
  }
}

const mapState = state => ({})

const mapDispatch = ({ ui: { setWindowSize } }) => ({
  setWindowSize,
})

export default connect(
  mapState,
  mapDispatch
)(WrapperComponent)
