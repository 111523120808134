import wretch from 'wretch'
import swal from 'sweetalert2'
import { API } from '../constants';
import i18n from '../i18n'

export const callApi = wretch().content('application/json').url(API)

export const apiCatch = error => {
    if (error.message && error.message.indexOf('fetch') >= 0)
      // api connection error
      callApi
        .url('/')
        .get()
        .json()
        .then(result => {
            if (result)
                location.reload()
        })
        .catch(() => {
            swal({ title: i18n.t('ERROR'), text: i18n.t('ERROR_API'), type: 'error' })
        })
    else
      // others errors
      throw error;
}