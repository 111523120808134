import { MOBILE_WIDTH, TABLET_WIDTH } from "../../../utils/style";

export const DeviceType = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop"
}

export const ui = {
  state: {
    width: null,
    height: null,
    device: DeviceType.DESKTOP
  },
  reducers: {
    setWindowSize(state, { width, height }) {
      let device = DeviceType.DESKTOP;
      if (width <= MOBILE_WIDTH) {
        device = DeviceType.MOBILE
      } else if (width <= TABLET_WIDTH) {
        device = DeviceType.TABLET
      }
      return {
        ...state,
        width,
        height,
        device
      }
    },
  },
  effects: {},
}
