import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Wrapper from './screens/Wrapper'
import { IS_TOTEM } from './constants'
import { countriesKeys } from './utils/countries'
import { getCurrentLanguage, setLanguage } from './language'

class App extends Component {
  constructor(props) {
    super(props)
    if (!IS_TOTEM) {
      this.addGoogleAnalyticsGlobalTagSiteConfig()
    }
  }

  addGoogleAnalyticsGlobalTagSiteConfig = () => {
    // <!-- Global site tag (gtag.js) - Google Analytics -->
    // <script async src="https://www.googletagmanager.com/gtag/js?id=UA-150386178-1"></script>
    // <script>
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){dataLayer.push(arguments);}
    // gtag('js', new Date());

    // gtag('config', 'UA-150386178-1');
    // </script>
    const uaId = 'UA-150386178-1'

    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${uaId}`
    document.head.append(script)

    const script2 = document.createElement('script')
    script2.text = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
    
        gtag('config', '${uaId}');`
    document.head.append(script2)
  }

  render() {
    if (IS_TOTEM) {
      return (
        <Switch>
          <Route path="" component={Wrapper} />
        </Switch>
      )
    } else {
      return (
        <Switch>
          <Route
            path={`/:lang(${countriesKeys.join('|')})`}
            render={props => {
              const {
                match: {
                  params: { lang },
                },
              } = props
              setLanguage(lang)
              return <Wrapper {...props} />
            }}
          />
          <Route
            path="*"
            render={({ location: { pathname, search } }) => {
              const currentLang = getCurrentLanguage()
              const redirectPath = '/' + currentLang + pathname + search

              return <Redirect to={redirectPath} />
            }}
          />
        </Switch>
      )
    }
  }
}

export default App
