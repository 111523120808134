// GLOBAL DEPLOY
//const HOST = 'https://wirtualnalazienka-api.tubadzin.pl' // - for DEPLOY ONLY! #deploy

import { settings } from './settings';

// TOTEM DEPLOY
const HOST = settings.HOST

export const API = `${HOST}/api`
export const STATIC = `${HOST}/static`

export const LANG_PACK = 'LANG_PACK'
// GLOBAL DEPLOY
export const IS_TOTEM = settings.IS_TOTEM
// TOTEM DEPLOY
//export const IS_TOTEM = true

export const TO_ASCII = {
  '188': '44',
  '109': '45',
  '190': '46',
  '191': '47',
  '192': '96',
  '220': '92',
  '222': '39',
  '221': '93',
  '219': '91',
  '173': '45',
  '187': '61', //IE Key codes
  '186': '59', //IE Key codes
  '189': '45', //IE Key codes
}

export const SHIFT_UPS = {
  '96': '~',
  '49': '!',
  '50': '@',
  '51': '#',
  '52': '$',
  '53': '%',
  '54': '^',
  '55': '&',
  '56': '*',
  '57': '(',
  '48': ')',
  '45': '_',
  '61': '+',
  '91': '{',
  '93': '}',
  '92': '|',
  '59': ':',
  '39': '"',
  '44': '<',
  '46': '>',
  '47': '?',
}
