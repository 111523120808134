import React from 'react';
import IdleMonitor from 'react-simple-idle-monitor'
import {connect} from 'react-redux';

const Monitor = ({onIdle, onActive}) => (        <IdleMonitor
    activeClassName={"idleMonitor--working"}
    timeout={1000*60*3}
    idleClassName={"idleMonitor"}
    onIdle={onIdle}
    onActive={onActive}
    />
)
const mapDispatch = ({idleMonitor: {onActive, onIdle}}) => {
    return {
    onIdle,
    onActive
  }};
export default connect(undefined, mapDispatch)(Monitor)
