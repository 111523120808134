import { init } from '@rematch/core'
import { user } from './models/user'
import { router } from './models/router'
import { session } from './models/session'
import { idleMonitor } from './models/idleMonitor'
import { combination } from './models/combination'
import { ui } from './models/ui'
import createReactRouterPlugin from './models/router/plugin'

const store = init({
  models: {
    user,
    router,
    session,
    idleMonitor,
    combination,
    ui,
  },
  plugins: [createReactRouterPlugin],
})

export default store
