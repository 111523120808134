import { callApi, apiCatch } from '../../../utils/api'

export const combination = {
  state: {
    email: '',
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    changeEmail(state, email) {
      return { ...state, email }
    },
  },
  effects: {
    sendEmail: async ({ email, combinationId, roomType, lang }, rootState) => {
      return callApi
        .url('/email/combination')
        .post({ email, combinationId, roomType: Number(roomType), lang })
        .res()
        .catch(apiCatch)
    },
  },
}
